<template>
  <power-bi-report :report-id="reportId" />
</template>

<script>
export default {
  components: {
    PowerBiReport: () => import('@/modules/external_bi/components/PowerBiReport')
  },
  computed: {
    reportId() {
      return '1b7cfb66-e2c0-4778-9415-f5c070a4377b'
    }
  }
}
</script>
